// import "./src/components/style.css"
// require('./src/components/style.css')


exports.onInitialClientRender = () => {
    // window.widget = window.cloudinary.createUploadWidget({
    //     cloudName: "in-memorial", 
    //     uploadPreset: "rzpbpeme-parker-public"
    // }, (error, result) => { });
    
    // document.getElementById('memoryLink').click = window.uploadMemory;
    
    // alert(window.uploadMemory)
}